import React from "react";
import { ButtonLoader } from "./ButtonLoader";
export const ContentLoader = ({ className, pleaseWait, size }) => (
  <div className="content-loader table-loader">
    <div className="d-flex justify-content-center flex-wrap">
      <div className="align-item-center">
        <ButtonLoader className={className} size={size} />
      </div>
    </div>
  </div>
);
