import React from "react";
export const TextColumnFormatter = (cellContent, row, rowIndex) => {
  const { added_comment } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block">{added_comment || "-"}</span>
      <> </>
    </>
  );
};
