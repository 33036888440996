import React from "react";
import { Button, Card } from "react-bootstrap";
import { AmazonOrdersFilter } from "./amazon-orders-filter/AmazonOrdersFilter";
import { AmazonOrdersTable } from "./amazon-orders-table/AmazonOrdersTable";

export function AmazonOrdersCard({
  isLoading,
  isLoadingForDownloadVideoAudio,
  isLoadingForEmail,
  amazonOrdersList,
  amazonOrdersListTotalCount,
  handleAddNewOrder,
  data,
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Amazon Orders</span>
          </h3>
          <div>
            <Button
              variant="btn btn-light-primary btn-md m-1"
              onClick={handleAddNewOrder}
            >
              <i className="fas fa-plus"></i> Add Charm
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <AmazonOrdersFilter />
            <AmazonOrdersTable
              isLoading={isLoading}
              isLoadingForDownloadVideoAudio={isLoadingForDownloadVideoAudio}
              isLoadingForEmail={isLoadingForEmail}
              amazonOrdersList={amazonOrdersList}
              amazonOrdersListTotalCount={amazonOrdersListTotalCount}
              data={data}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
