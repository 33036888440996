import download from "downloadjs";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { getQRCodeUrl } from "../../../axios/services/apiList";
import {
  addNewCharmApi,
  amazonOrdersApi,
  editCharmApi,
  emailCharmApi,
} from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { isUnauthorized } from "../../utils/utils";
import AddNewCharmModal from "../Modal/AddNewCharmModal";
import ErrorModal from "../Modal/ErrorModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { AmazonOrdersEditDialog } from "./amazon-orders-edit-dialog/AmazonOrdersEditDialog";
import AmazonOrdersViewModal from "./amazon-orders-view-modal/AmazonOrdersViewModal";
import { AmazonOrdersCard } from "./AmazonOrdersCard";
import { AmazonOrdersUIProvider } from "./AmazonOrdersUIContext";
import { initialFilter } from "./AmazonOrdersUIHelpers";

export function AmazonOrdersPage({ history }) {
  const [
    isLoadingForDownloadVideoAudio,
    setLoadingForDownloadVideoAudio,
  ] = useState(false);
  const [isLoadingForEmail, setLoadingForEmail] = useState(false);
  const [
    isLoadingForEditAmazonOrders,
    setLoadingForEditAmazonOrders,
  ] = useState(false);
  const [loadingAddNewCharm, setLoadingAddNewCharm] = useState(false);
  const [isAddNewCharmModal, setAddNewCharmModal] = useState(false);
  const [isAmazonOrdersViewModal, setAmazonOrdersViewModal] = useState(false);
  const [isAmazonOrdersEditModal, setAmazonOrdersEditModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [amazonOrdersList, setAmazonOrdersList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [amazonOrdersListTotalCount, setAmazonOrdersListTotalCount] = useState(
    0
  );
  const [updateOrderList, setUpdateOrderList] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");

  async function getOrderList() {
    enableLoading();
    const amazonOrdersResponse = await amazonOrdersApi({ queryParams });
    disableLoading();
    const { Error, data, count } = amazonOrdersResponse || {};
    if (Error) {
      isUnauthorized({ response: amazonOrdersResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setAmazonOrdersList(data);
    setAmazonOrdersListTotalCount(count);
  }

  useEffect(() => {
    getOrderList();
  }, [updateOrderList, queryParams]);

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingDownloadVideoAudio = () => {
    setLoadingForDownloadVideoAudio(true);
  };

  const disableLoadingDownloadVideoAudio = () => {
    setLoadingForDownloadVideoAudio(false);
  };

  const enableLoadingEmail = () => {
    setLoadingForEmail(true);
  };

  const disableLoadingEmail = () => {
    setLoadingForEmail(false);
  };

  const enableLoadingAddNewCharm = () => {
    setLoadingAddNewCharm(true);
  };

  const disableLoadingAddNewCharm = () => {
    setLoadingAddNewCharm(false);
  };

  const enableLoadingEditAmazonOrders = () => {
    setLoadingForEditAmazonOrders(true);
  };

  const disableLoadingEditAmazonOrders = () => {
    setLoadingForEditAmazonOrders(false);
  };

  const handleAddNewCharmModalShow = () => {
    setAddNewCharmModal(true);
  };

  const handleAddNewCharmModalHide = () => {
    setAddNewCharmModal(false);
  };

  const handleAmazonOrdersViewModalShow = () => {
    setAmazonOrdersViewModal(true);
  };

  const handleAmazonOrdersViewModalHide = () => {
    setAmazonOrdersViewModal(false);
  };

  const handleAmazonOrdersEditModalShow = () => {
    setAmazonOrdersEditModal(true);
  };

  const handleAmazonOrdersEditModalHide = () => {
    setAmazonOrdersEditModal(false);
  };

  const handleAddNewOrder = () => {
    handleAddNewCharmModalShow();
  };

  const handleAddNewCharmAdded = () => {
    setUpdateOrderList(Math.random());
    handleAddNewCharmModalHide();
  };

  const amazonOrdersUIEvents = {
    onClickOfAmazonOrdesViewActionButton: (row) => {
      setData(row);
      handleAmazonOrdersViewModalShow();
    },
    onClickOfDownloadVideoAudioActionButton: (row) => {
      setData(row);
      const { media_file } = row || {};
      if (media_file) {
        download(media_file);
      }
    },
    onClickOfDownloadQRCodeActionButton: (row) => {
      setData(row);
      const { order_id, product_sku } = row || {};
      let qrCodeUrl = getQRCodeUrl({
        order_id,
        product_sku,
      });
      // console.log("qrCodeUrl", qrCodeUrl);
      download(qrCodeUrl);
    },
    onClickOfAmazonOrdesEditActionButton: (row) => {
      setData(row);
      handleAmazonOrdersEditModalShow();
    },
    onClickOfEmailActionButton: (row) => {
      setData(row);
      emailOrder(row);
    },
  };

  const addNewCharm = async (values) => {
    const { user } = userInstance || {};
    const [firstUser] = user || {};
    const { id } = firstUser || {};

    const {
      order_id,
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      address1,
      address2,
      city,
      state,
      zipcode,
      products,
    } = values || {};
    enableLoadingAddNewCharm();
    const addNewCharmResponse = await addNewCharmApi({
      order_id: parseInt(order_id),
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      shipping_detail: {
        address1,
        address2,
        city,
        state,
        zipcode,
      },
      created_by: id,
      products,
    });
    disableLoadingAddNewCharm();
    const { Error } = addNewCharmResponse || {};
    if (Error) {
      isUnauthorized({ response: addNewCharmResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("Charm Added Successfully.");
    handleSuccessModalShow();
    handleAddNewCharmAdded();
  };

  const editCharmOrder = async (values) => {
    const { charmdetailsId: charm_detail_id } = data || {};
    const {
      order_id,
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      address1,
      address2,
      city,
      state,
      zipcode,
      products,
    } = values || {};
    const [product] = products || [];
    const { product_sku, media_file, added_comment } = product || {};
    enableLoadingEditAmazonOrders();
    const editCharmResponse = await editCharmApi({
      charm_detail_id: parseInt(charm_detail_id),
      order_id: parseInt(order_id),
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      shipping_detail: { address1, address2, city, state, zipcode },
      product_sku,
      media_file,
      added_comment,
    });
    disableLoadingEditAmazonOrders();
    const { Error } = editCharmResponse || {};
    if (Error) {
      isUnauthorized({ response: editCharmResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("Charm Edited Successfully.");
    handleSuccessModalShow();
    handleAmazonOrdersEditModalHide();
    setUpdateOrderList(Math.random());
  };

  const emailOrder = async (data) => {
    const { id } = data || {};
    enableLoadingEmail();
    const emailCharmResponse = await emailCharmApi({
      charm_detail_id: id,
    });
    disableLoadingEmail();
    const { Error } = emailCharmResponse || {};
    if (Error) {
      isUnauthorized({ response: emailCharmResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("Email Sent Successfully.");
    handleSuccessModalShow();
  };

  return (
    <>
      <AmazonOrdersUIProvider
        amazonOrdersUIEvents={amazonOrdersUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        <AmazonOrdersCard
          isLoading={isLoading}
          isLoadingForDownloadVideoAudio={isLoadingForDownloadVideoAudio}
          isLoadingForEmail={isLoadingForEmail}
          amazonOrdersList={amazonOrdersList}
          amazonOrdersListTotalCount={amazonOrdersListTotalCount}
          data={data}
          handleAddNewOrder={handleAddNewOrder}
        />
        {isAddNewCharmModal && (
          <AddNewCharmModal
            loadingAddNewCharm={loadingAddNewCharm}
            isAddNewCharmModal={isAddNewCharmModal}
            handleAddNewCharmModalHide={handleAddNewCharmModalHide}
            onClickOfCancelButton={handleAddNewCharmModalHide}
            addNewCharm={addNewCharm}
          />
        )}
        {isAmazonOrdersViewModal && (
          <AmazonOrdersViewModal
            isAmazonOrdersViewModal={isAmazonOrdersViewModal}
            handleAmazonOrdersViewModalHide={handleAmazonOrdersViewModalHide}
            data={data}
            onClickOfCancelButton={handleAmazonOrdersViewModalHide}
          />
        )}
        {isAmazonOrdersEditModal && (
          <AmazonOrdersEditDialog
            isLoadingForEditAmazonOrders={isLoadingForEditAmazonOrders}
            isAmazonOrdersEditModal={isAmazonOrdersEditModal}
            handleAmazonOrdersEditModalHide={handleAmazonOrdersEditModalHide}
            data={data}
            onClickOfCancelButton={handleAmazonOrdersEditModalHide}
            editCharmOrder={editCharmOrder}
          />
        )}
      </AmazonOrdersUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
