import React from "react";
export const WordpressCommentColumnFormatter = (cellContent, row, rowIndex) => {
  const { line_items } = row || {};
  let commentStrings = [];
  for (let index = 0; index < line_items.length; index++) {
    const element = line_items[index];
    const { meta_data } = element || {};
    for (let j = 0; j < meta_data.length; j++) {
      const meta = meta_data[j];
      const { key, value } = meta || {};
      if (key === "comment") {
        commentStrings.push(value);
      }
    }
  }

  return (
    <>
      {commentStrings.map((comment, index) => {
        return (
          <div className="row" key={index}>
            <li className={`dot-color`}>
              <span className="text-dark">{comment ? comment : "-"}</span>{" "}
            </li>
          </div>
        );
      })}

      <> </>
    </>
  );
};
