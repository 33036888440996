import React from "react";
import { Button } from "react-bootstrap";
export const VideoAudioColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onClickOfDownloadVideoAudioActionButton,
    isLoadingForDownloadVideoAudio,
    data,
  }
) => {
  const { media_file } = row || {};
  const renderDownloadButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-primary btn-sm mx-1"
        onClick={() => {
          onClickOfDownloadVideoAudioActionButton(row);
        }}
      >
        {/* <ButtonLoader /> */}
        <i className="fas fa-download"></i>
      </Button>
    );
  };

  return <>{media_file ? renderDownloadButton() : <>-</>}</>;
};
