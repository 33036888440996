import moment from "moment";
import { SERVER_URL } from "../../app/ServerConfig";
import { YYYY_MM_DD } from "../../app/utils/const";

export function loginUrl() {
  let url = "/orders/login";
  return url;
}

export function uploadProfilePicUrl() {
  let url = "/uploads/upload";
  return url;
}

export function recentHistoryUrl() {
  let url = "/transactions/getRecentHistory";
  return url;
}

export function productListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getProduct?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function accessLogListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getaccessLog?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function getImageBasedOnNameUrl(imageName) {
  let url = `${SERVER_URL}/downloads/download?folder_name=profilepic&image=${imageName}&id=${localStorage.getItem(
    "auth-token"
  )}`;
  return url;
}

export function getPdfBasedOnNameUrl({ file, folder }) {
  let url = `/downloads/download?folder_name=${
    folder ? folder : ""
  }&image=${file}&id=${localStorage.getItem("auth-token")}`;
  return url;
}

export function recentHistoryPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function recentAccessLogPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function addProductUrl() {
  let url = `/addProduct`;
  return url;
}

export function editProductUrl() {
  let url = `/editProduct`;
  return url;
}

export function logoutUrl({ userId }) {
  let url = `/orders/logout/${userId}`;
  return url;
}

export function deleteProductUrl({ id }) {
  let url = `/removeProduct/${id}`;
  return url;
}

export function exportProductsUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;

  let url = `/exportCSV?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }

  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }

  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function exportAccessLogUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, is_validate, date } =
    filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/exportlogs?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function uploadDocumentUrl() {
  let url = `/upload`;
  return url;
}

export function importProductsUrl() {
  let url = `/importProducts`;
  return url;
}

export function syncOrderIdUrl({ orderId }) {
  let url = `/orders/validate/${orderId}`;
  return url;
}

export function downloadOrderAttachmentUrl({ fme_upload_files_name }) {
  let url = `https://charmbykaalo.com/wp-content/uploads/${fme_upload_files_name}`;
  return url;
}

export function uploadOrderAttachmentUrl({ orderId }) {
  let url = `/orders/upload/${orderId}`;
  return url;
}

export function generateOrderLinkUrl({ orderId }) {
  let url = `/orders/generatelink/${orderId}`;
  return url;
}

export function emailOrderUrl() {
  let url = `/orders/mail`;
  return url;
}

export function orderCommentsUrl() {
  let url = `/orders/storeComment`;
  return url;
}

export function addNewCharmUrl() {
  let url = `/orders/charm`;
  return url;
}

export function editCharmUrl() {
  let url = `/orders/charm`;
  return url;
}

export function uploadProductMediaUrl({ orderId }) {
  let url = `/orders/charmsUpload/${orderId}`;
  return url;
}

export function amazonOrdersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const skip = (pageNumber - 1) * pageSize;
  let url = `/orders/charms?limit=${pageSize}&offset=${skip}`;
  const { id, orderId, email, date } = filter || {};
  const { datefrom, dateto } = date || {};

  // Below code is for search
  if (id) {
    url = url.concat(`&filter[charmId]=${id}`);
  }
  if (orderId) {
    url = url.concat(`&filter[order_id]=${orderId}`);
  }
  if (email) {
    url = url.concat(`&filter[buyer_email]=${email}`);
  }

  // Below code is for date filter
  if (date) {
    const fromDate = moment(datefrom).format(YYYY_MM_DD);
    const toDate = moment(dateto).format(YYYY_MM_DD);
    url = url.concat(`&filter[fromdate]=${fromDate}&filter[todate]=${toDate}`);
  }

  // Below code is for Sorting Id and Date
  if (sortField && sortOrder) {
    url = url.concat(`&filter[orderBy]=${sortField} ${sortOrder}`);
  }
  return url;
}

export function emailCharmUrl() {
  let url = `/orders/charmEmail`;
  return url;
}

export function getQRCodeUrl({ order_id, product_sku }) {
  let url = `${SERVER_URL}/orders/download?filename=${order_id}_${product_sku}__qrcode.png`;
  return url;
}
