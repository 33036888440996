import React from "react";
import { Button } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";
export const WordpressVideoColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onClickOfDownloadVideoActionButton,
    syncOrderIds,
    isLoadingForDownloadVideoAttachment,
    data,
  }
) => {
  const { meta_data, id } = row || {};
  const { id: orderId } = data || {};
  let isVisible = false;
  for (let index = 0; index < meta_data.length; index++) {
    const element = meta_data[index];
    const { key, value } = element || {};
    if (key === "_wcuf_uploaded_files") {
      const values = Object.values(value);
      for (let i = 0; i < values.length; i++) {
        const mediaFile = values[i];
        const { ID3_info } = mediaFile;
        const [mediaInfo] = ID3_info || {};
        const { type } = mediaInfo || {};
        if (type === "video") {
          isVisible = true;
        }
      }
    }
  }

  const renderDownloadButton = () => {
    return (
      isVisible && (
        <Button
          variant="btn btn-icon btn-light-primary btn-sm mx-1"
          onClick={() => {
            onClickOfDownloadVideoActionButton(row);
          }}
        >
          {orderId === id && isLoadingForDownloadVideoAttachment ? (
            <ButtonLoader />
          ) : (
            <i className="fas fa-download"></i>
          )}
        </Button>
      )
    );
  };

  return <>{renderDownloadButton()}</>;
};
