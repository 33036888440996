/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onClickOfAmazonOrdesViewActionButton,
    onClickOfAmazonOrdesEditActionButton,
    onClickOfEmailActionButton,
    isLoadingForEmail,
    data,
  }
) => {
  const { id } = row || {};
  const { id: orderId } = data || {};

  const renderAmazonOrdesViewButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-primary btn-sm mx-1"
        onClick={() => {
          onClickOfAmazonOrdesViewActionButton(row);
        }}
      >
        <i className="fas fa-eye"></i>
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-info btn-sm mx-1 font-size-sm"
        onClick={() => {
          onClickOfAmazonOrdesEditActionButton(row);
        }}
      >
        <i className="fas fa-edit"></i>
      </Button>
    );
  };

  const renderMailButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-warning btn-sm mx-1"
        onClick={() => {
          onClickOfEmailActionButton(row);
        }}
      >
        {orderId === id && isLoadingForEmail ? (
          <ButtonLoader />
        ) : (
          <i className="fas fa-envelope"></i>
        )}
      </Button>
    );
  };

  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-end">
        {renderAmazonOrdesViewButton()}
        {renderEditButton()}
        {renderMailButton()}
      </ButtonToolbar>
      <> </>
    </>
  );
};
