import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const AmazonOrdersUIContext = createContext();

export function useAmazonOrdersUIContext() {
  return useContext(AmazonOrdersUIContext);
}

export const AmazonOrdersUIConsumer = AmazonOrdersUIContext.Consumer;

export function AmazonOrdersUIProvider({
  amazonOrdersUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    onClickOfDownloadVideoAudioActionButton:
      amazonOrdersUIEvents.onClickOfDownloadVideoAudioActionButton,
    onClickOfDownloadQRCodeActionButton:
      amazonOrdersUIEvents.onClickOfDownloadQRCodeActionButton,
    onClickOfAmazonOrdesViewActionButton:
      amazonOrdersUIEvents.onClickOfAmazonOrdesViewActionButton,
    onClickOfAmazonOrdesEditActionButton:
      amazonOrdersUIEvents.onClickOfAmazonOrdesEditActionButton,
    onClickOfEmailActionButton: amazonOrdersUIEvents.onClickOfEmailActionButton,
  };

  return (
    <AmazonOrdersUIContext.Provider value={value}>
      {children}
    </AmazonOrdersUIContext.Provider>
  );
}
