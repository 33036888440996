export const CONSUMER_KEY = "ck_e3edcb6ea3892069aa8cb4460fa9401b49e174f1";
export const CONSUMER_SECRET = "cs_e2f058b4f7e3b442fce35bd76c33c5cda272139d";
export const PRODUCTS = "Products";
export const ACCESS_LOG = "Access Log";
export const YYYY_MM_DD = "YYYY-MM-DD";
export const YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:MM";
export const YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDTHH:MM:SS";
export const dd_MM_yyyy_HH_MM = "dd/MM/yyyy HH:MM";
export const dd_MM_yyyy = "dd/MM/yyyy";
export const STR_EXCEL = "xlsx";
export const STR_CSV = "csv";
export const FILE_EXTENTION = "application/pdf,.doc,.docx,.xlsx,.xls";
export const MB_20 = 20971520;
export const MB_5 = 5242880;
export const AUDIO_EXTENTION = "";
export const VIDEO_EXTENTION = "";
