import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { thumbInnerStyle, thumbsContainerStyle, thumbStyle } from "./CSSMaster";

export const ThumbnailWrapper = ({
  file,
  handleOnDeleteOfAttachment,
  uploadFileType,
}) => {
  return (
    <aside style={thumbsContainerStyle}>
      <div style={{ width: "100px" }}>
        <div style={thumbStyle}>
          <div style={thumbInnerStyle}>
            {uploadFileType.includes("video") ? (
              <SVG src={toAbsoluteUrl(`/media/svg/files/mp4.svg`)} />
            ) : (
              <img
                src={toAbsoluteUrl("/media/svg/files/mp3.png")}
                alt=""
                className="h-75 align-self-center"
              />
            )}

            <i
              className="fas fa-times text-danger"
              onClick={() => {
                handleOnDeleteOfAttachment();
              }}
            ></i>
          </div>
        </div>
        {/* <p className="mt-2" style={{ fontSize: "12px", lineHeight: "16px" }}>
          {file}
        </p> */}
      </div>
    </aside>
  );
};
