import React from "react";
import { Button } from "react-bootstrap";
export const S3VideoColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onClickOfUploadVideoActionButton, syncOrderIds, data }
) => {
  const { id, meta_data } = row || {};
  const { id: orderId } = data || {};
  let isVisible = false;
  for (let index = 0; index < meta_data.length; index++) {
    const element = meta_data[index];
    const { key, value } = element || {};
    if (key === "_wcuf_uploaded_files") {
      const values = Object.values(value);
      for (let i = 0; i < values.length; i++) {
        const mediaFile = values[i];
        const { ID3_info } = mediaFile;
        const [mediaInfo] = ID3_info || {};
        const { type } = mediaInfo || {};
        if (type === "video") {
          isVisible = true;
        }
      }
    }
  }

  const renderUploadButton = () => {
    return (
      isVisible && (
        <Button
          variant="btn btn-icon btn-light-warning btn-sm mx-1 font-size-sm"
          onClick={() => {
            onClickOfUploadVideoActionButton(row);
          }}
        >
          <i className="fas fa-upload"></i>
        </Button>
      )
    );
  };
  return <>{renderUploadButton()}</>;
};
