import React from "react";
import { Button } from "react-bootstrap";
export const QRCodeColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onClickOfDownloadQRCodeActionButton, isLoadingForDownloadVideoAudio, data }
) => {
  const renderDownloadButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-success btn-sm mx-1"
        onClick={() => {
          onClickOfDownloadQRCodeActionButton(row);
        }}
      >
        {/* <ButtonLoader /> */}
        <i className="fas fa-download"></i>
      </Button>
    );
  };

  return <>{renderDownloadButton()}</>;
};
