/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MMM_YYYY_HH_MM } from "../../../../utils/dateTimeFormats";

export const DateTimeColumnFormatter = (cellContent, row, rowIndex) => {
  const { created_at } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block">
        <Moment date={created_at} format={DD_MMM_YYYY_HH_MM} />
      </span>
      <> </>
    </>
  );
};
