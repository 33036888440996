import download from "downloadjs";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from "react-moment";
import { DD_MMM_YYYY_HH_MM } from "../../../utils/dateTimeFormats";

function AmazonOrdersViewModal({
  isAmazonOrdersViewModal,
  handleAmazonOrdersViewModalHide,
  data,
  onClickOfCancelButton,
}) {
  const {
    id,
    order_id,
    product_sku,
    added_comment,
    buyer_name,
    buyer_email,
    recipient_name,
    recipient_email,
    shipping_detail,
    created_at,
    media_file,
  } = data || {};

  let shippingDetail = {};
  if (shipping_detail) {
    shippingDetail = JSON.parse(shipping_detail) || {};
  }
  const { address1, address2, city, state, zipcode } = shippingDetail || {};
  let shippingAddress = "";
  if (address1) {
    shippingAddress = address1;
  }
  if (address2) {
    shippingAddress = shippingAddress.concat(`, ${address2}`);
  }
  if (city) {
    shippingAddress = shippingAddress.concat(`, ${city}`);
  }
  if (state) {
    shippingAddress = shippingAddress.concat(`, ${state}`);
  }
  if (zipcode) {
    shippingAddress = shippingAddress.concat(`-${zipcode}`);
  }
  return (
    <>
      <Modal
        show={isAmazonOrdersViewModal}
        onHide={handleAmazonOrdersViewModalHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            View Order
          </h3>
          <p className="text-center h4 font-weight-boldest text-dark mb-10 mt-2">
            {id}
          </p>

          <div className="row">
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Order No
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {order_id || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Product SKU
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {product_sku || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Video/Audio
              </h5>
              <div className="fs-14 font-weight-bold text-left cursor-pointer">
                {media_file ? (
                  <i
                    className="fas fa-download text-primary"
                    onClick={() => {
                      if (media_file) {
                        download(media_file);
                      }
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Text
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {added_comment || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Buyer's Name
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {buyer_name || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Buyer's Email
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left text-break">
                {buyer_email || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Recipient's Name
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left text-break">
                {recipient_name || "-"}
              </p>
            </div>

            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Recipient's Email
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left text-break">
                {recipient_email || "-"}
              </p>
            </div>

            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Shipping Address
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {shippingAddress || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Date
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                <Moment date={created_at} format={DD_MMM_YYYY_HH_MM} />
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="btn-lg btn-light-primary mt-5 mb-5 px-10"
            onClick={onClickOfCancelButton}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AmazonOrdersViewModal;
