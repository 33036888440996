import React from "react";
import { Button } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";
export const WordpressAudioColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onClickOfDownloadAudioActionButton,
    syncOrderIds,
    isLoadingForDownloadAudioAttachment,
    data,
  }
) => {
  const { id, meta_data } = row || {};
  const { id: orderId } = data || {};
  let isVisible = false;
  for (let index = 0; index < meta_data.length; index++) {
    const element = meta_data[index];
    const { key, value } = element || {};
    if (key === "_wcuf_uploaded_files") {
      const values = Object.values(value);
      for (let i = 0; i < values.length; i++) {
        const mediaFile = values[i];
        const { ID3_info } = mediaFile;
        const [mediaInfo] = ID3_info || {};
        const { type } = mediaInfo || {};
        if (type === "audio") {
          isVisible = true;
        }
      }
    }
  }

  const renderDownloadButton = () => {
    return (
      isVisible && (
        <Button
          variant="btn btn-icon btn-light-success btn-sm mx-1"
          onClick={() => {
            onClickOfDownloadAudioActionButton(row);
          }}
        >
          {orderId === id && isLoadingForDownloadAudioAttachment ? (
            <ButtonLoader />
          ) : (
            <i className="fas fa-download"></i>
          )}
        </Button>
      )
    );
  };

  return <>{renderDownloadButton()}</>;
};
