import axios from "axios";
import download from "downloadjs";
import fileDownload from "js-file-download";
import { SERVER_URL } from "../app/ServerConfig";

export const getCall = (url) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getPDFCall = (url, customPdfName, extention) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        const content = res.headers["content-type"];
        download(res.data, `${customPdfName}.${extention}`, content);
        resolve({ res });
      })
      .catch((error) => {
        console.log("error", error);
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getMediaCall = (url, filename) =>
  new Promise(async (resolve) => {
    const baseUrl = url;
    axios
      .get(baseUrl, {
        // headers: {
        //   Accept: "application/json",
        // },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        resolve({ res });
      })
      .catch((error) => {
        console.log("error", error);
        if (error && error.response) {
          resolve(error.response.data);
        } else {
          resolve({ error });
        }
      });
  });

export const getPDFPrintCall = (url) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .post(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const patchCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .patch(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const putCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .put(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const deleteCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .delete(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCallWithMedia = (url, data = {}) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    const formData = new FormData();
    formData.append(data.container, data.file);
    axios({
      method: "POST",
      url: baseUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
      },
    })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });
