import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function UpdatedSuccessfulModal({
  message,
  UpdatedSuccessfulModalShow,
  UpdatedSuccessfulModalHide,
  errorButtonLabel,
  errorOnClick,
}) {
  return (
    <>
      <Modal
        centered
        show={UpdatedSuccessfulModalShow}
        onHide={UpdatedSuccessfulModalHide}
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            <div className="success-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Check.svg"
                  )}
                />
              </span>
            </div>
          </div>
          <p className="text-center fs-16 text-dark confirmation-content">
            {message}
            {/* Your password has been changed successfully */}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UpdatedSuccessfulModal;
