import React from "react";
export const WordpressTextColumnFormatter = (cellContent, row, rowIndex) => {
  // console.log("row", row);
  const { customer_note } = row || {};
  // console.log("customer_note", customer_note);
  return (
    <>
      <span className="text-dark-75 d-block">{customer_note || "-"}</span>
      <> </>
    </>
  );
};
