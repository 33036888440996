import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { useHistoryUIContext } from "../HistoryUIContext";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { dd_MM_yyyy, YYYY_MM_DD_T_HH_MM_SS } from "../../../utils/const";
import moment from "moment";

const initialFilterValues = {
  orderId: "",
  datefrom: "",
  dateto: "",
};

export function HistoryFilter({}) {
  const [datefrom, setDateFrom] = useState("");
  const [dateto, setDateTo] = useState("");

  const historyUIContext = useHistoryUIContext();
  const historyUIUIProps = useMemo(() => {
    return {
      queryParams: historyUIContext.queryParams,
      setQueryParams: historyUIContext.setQueryParams,
    };
  }, [historyUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { orderId, datefrom, dateto } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.orderId = orderId !== "" ? orderId : undefined;
    if (datefrom && dateto) {
      const date = {
        datefrom: datefrom
          ? moment(datefrom).format(YYYY_MM_DD_T_HH_MM_SS)
          : undefined,
        dateto: dateto
          ? moment(dateto).format(YYYY_MM_DD_T_HH_MM_SS)
          : undefined,
      };
      filter.date = date !== "" ? date : undefined;
    }

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(historyUIUIProps.queryParams, values);
    if (!isEqual(newQueryParams, historyUIUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      historyUIUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              setDateFrom(null);
              setDateTo(null);
              handleReset();
            }}
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="orderId"
                  placeholder="Search Order ID"
                  onBlur={handleBlur}
                  value={values.orderId}
                  onChange={(e) => {
                    setFieldValue("orderId", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> Order ID
                </small>
              </div>
              {/* <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="serial_number"
                  placeholder="Search No. of Counts"
                  onBlur={handleBlur}
                  value={values.serial_number}
                  onChange={(e) => {
                    setFieldValue("serial_number", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> No. of Counts
                </small>
              </div> */}

              <div className="col-lg-2">
                <DatePicker
                  dateFormat={dd_MM_yyyy}
                  className="mb-1 form-control"
                  selected={datefrom}
                  onChange={(date) => {
                    setDateFrom(date);
                    setFieldValue("datefrom", date);
                    handleSubmit();
                  }}
                  placeholderText={"From"}
                  selectsStart
                  startDate={datefrom}
                  endDate={dateto}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Date
                </small>
              </div>

              <div className="col-lg-2">
                <DatePicker
                  className="form-control"
                  dateFormat={dd_MM_yyyy}
                  selected={dateto}
                  onChange={(date) => {
                    setDateTo(date);
                    setFieldValue("dateto", date);
                    handleSubmit();
                  }}
                  placeholderText={"To"}
                  selectsEnd
                  startDate={datefrom}
                  endDate={dateto}
                  minDate={datefrom}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
