import { FieldArray, Form, Formik } from "formik";
// import "node_modules/video-react/dist/video-react.css";
import React, { useState } from "react";
import { Button, ButtonToolbar, Card, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import SVG from "react-inlinesvg";
import { Player } from "video-react";
import "../../../../node_modules/video-react/dist/video-react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { uploadProductMediaApi } from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { MB_20 } from "../../utils/const";
import { ContentLoader } from "../../utils/ContentLoader";
import ErrorMessage from "../../utils/ErrorMessage";
import { AddNewCharmSchema } from "../../utils/FormikSchema";
import { isUnauthorized } from "../../utils/utils";
import ErrorModal from "./ErrorModal";
import UpdatedSuccessfulModal from "./UpdatedSuccessfulModal";
import ReactAudioPlayer from "react-audio-player";

const initialValues = {
  order_id: "",
  buyer_name: "",
  buyer_email: "",
  recipient_name: "",
  recipient_email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  products: [
    {
      product_sku: "",
      media_file: "",
      added_comment: "",
      fileBeforeUpload: "",
    },
  ],
};

function AddNewCharmModal({
  loadingAddNewCharm,
  isAddNewCharmModal,
  handleAddNewCharmModalHide,
  onClickOfCancelButton,
  addNewCharm,
}) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);

  const enableLoadingFileUploading = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUploading = () => {
    setFileUploadLoading(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Modal
        show={isAddNewCharmModal}
        onHide={() => {
          handleAddNewCharmModalHide();
        }}
        dialogClassName="modal-90w modal-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddNewCharmSchema}
          onSubmit={async (values) => {
            addNewCharm(values);
          }}
        >
          {({
            touched,
            errors,
            values,
            resetForm,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title
                  className="d-flex justify-content-between w-100"
                  id="contained-modal-title-vcenter"
                >
                  Add New Charm
                  <Button
                    variant="btn p-0 d-flex justify-content-end"
                    onClick={handleAddNewCharmModalHide}
                  >
                    <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Close.svg"
                        )}
                      />
                    </span>
                  </Button>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group mt-5">
                  <input
                    placeholder="Order No*"
                    autoComplete="off"
                    type="text"
                    className="form-control h-auto py-5 px-6 mb-2"
                    name="order_id"
                    value={values.order_id}
                    onChange={(event) => {
                      const { target } = event || {};
                      const { value } = target || {};
                      setFieldValue("order_id", value);
                    }}
                  />
                  {touched.order_id && errors.order_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {errors.order_id}
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        placeholder="Buyer's Name*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="buyer_name"
                        value={values.buyer_name}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("buyer_name", value);
                        }}
                      />
                      {touched.buyer_name && errors.buyer_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.buyer_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <input
                        placeholder="Buyer's Email*"
                        autoComplete="off"
                        type="email"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="buyer_email"
                        value={values.buyer_email}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("buyer_email", value);
                        }}
                      />
                      {touched.buyer_email && errors.buyer_email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.buyer_email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <input
                        placeholder="Recipient's Name*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="recipient_name"
                        value={values.recipient_name}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("recipient_name", value);
                        }}
                      />
                      {touched.recipient_name && errors.recipient_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.recipient_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <input
                        placeholder="Recipient's Email*"
                        autoComplete="off"
                        type="email"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="recipient_email"
                        value={values.recipient_email}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("recipient_email", value);
                        }}
                      />
                      {touched.recipient_email && errors.recipient_email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.recipient_email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-3 d-block">
                    <span className="text-dark font-weight-boldest font-size-h5">
                      Shipping Address
                    </span>
                  </div>

                  <div className="row mt-3">
                    {/* // Address 1 */}
                    <div className="col-md-6">
                      <input
                        placeholder="Address 1*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="address1"
                        value={values.address1}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("address1", value);
                        }}
                      />
                      {touched.address1 && errors.address1 ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.address1}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* // Address 2 */}
                    <div className="col-md-6">
                      <input
                        placeholder="Address 2*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="address2"
                        value={values.address2}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("address2", value);
                        }}
                      />
                      {touched.address2 && errors.address2 ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.address2}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* // City  */}
                    <div className="col-md-6">
                      <input
                        placeholder="City*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="city"
                        value={values.city}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("city", value);
                        }}
                      />
                      {touched.city && errors.city ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.city}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* // State */}
                    <div className="col-md-6">
                      <input
                        placeholder="State*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="state"
                        value={values.state}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("state", value);
                        }}
                      />
                      {touched.state && errors.state ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.state}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* // Zipcode */}
                    <div className="col-md-6">
                      <input
                        placeholder="Zipcode*"
                        autoComplete="off"
                        type="text"
                        className="form-control h-auto py-5 px-6 mb-2"
                        name="zipcode"
                        value={values.zipcode}
                        onChange={(event) => {
                          const { target } = event || {};
                          const { value } = target || {};
                          setFieldValue("zipcode", value);
                        }}
                      />
                      {touched.zipcode && errors.zipcode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {errors.zipcode}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <FieldArray name="products">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.products.length > 0 &&
                          values.products.map((product, index) => (
                            <Card
                              key={index}
                              className="card-custom card-stretch make-payment-wrapper"
                            >
                              <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
                                <h3 className="card-title align-items-start flex-column">
                                  <span className="font-weight-bold text-dark">
                                    Product {index + 1}
                                  </span>
                                </h3>
                                <div>
                                  <Button
                                    variant="btn btn-light-primary btn-sm mr-1"
                                    onClick={() =>
                                      push({
                                        product_sku: "",
                                        media_file: "",
                                        added_comment: "",
                                        fileBeforeUpload: "",
                                      })
                                    }
                                  >
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                  <Button
                                    variant="btn btn-light-danger btn-sm"
                                    onClick={() => remove(index)}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <div className="row">
                                  <div className="col-md-6">
                                    <input
                                      placeholder="Product SKU*"
                                      autoComplete="off"
                                      type="text"
                                      className="form-control h-auto py-5 px-6 mb-2"
                                      name="product_sku"
                                      value={values.products[index].product_sku}
                                      onChange={(event) => {
                                        const { target } = event || {};
                                        const { value } = target || {};
                                        setFieldValue(
                                          `products.${index}.product_sku`,
                                          value
                                        );
                                      }}
                                    />
                                    {touched.products &&
                                    errors.products &&
                                    errors.products.length > 0 &&
                                    errors.products[index] ? (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          <ErrorMessage
                                            id={
                                              errors.products[index].product_sku
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    <textarea
                                      name="added_comment"
                                      className="form-control h-auto py-5 mt- px-6 mb-2 form-control-simple no-resize"
                                      rows="5"
                                      id="default-textarea"
                                      placeholder="Type Comment..."
                                      value={
                                        values.products[index].added_comment
                                      }
                                      onChange={(event) => {
                                        const { target } = event || {};
                                        const { value } = target || {};
                                        setFieldValue(
                                          `products.${index}.added_comment`,
                                          value
                                        );
                                      }}
                                    ></textarea>
                                    {touched.products &&
                                    errors.products &&
                                    errors.products.length > 0 &&
                                    errors.products[index] ? (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          <ErrorMessage
                                            id={
                                              errors.products[index]
                                                .added_comment
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-6">
                                    <Dropzone
                                      disabled={
                                        values.products[index].media_file
                                          ? true
                                          : false
                                      }
                                      accept={{
                                        "audio/aac": [".aac"],
                                        "audio/midi": [".mid"],
                                        "audio/x-midi": [".midi"],
                                        "audio/mpeg": [".mp3"],
                                        "audio/ogg": [".oga"],
                                        "audio/opus": [".opus"],
                                        "audio/wav": [".wav"],
                                        "audio/webm": [".weba"],
                                        "audio/3gpp": [".3gp"],
                                        "audio/3gpp2": [".3g2"],
                                        "video/x-msvideo": [".avi"],
                                        "video/mp4": [".mp4"],
                                        "video/mpeg": [".mpeg"],
                                        "video/ogg": [".ogv"],
                                        "video/mp2t": [".ts"],
                                        "video/webm": [".webm"],
                                        "video/3gpp": [".3gp"],
                                        "video/3gpp2": [".3g2"],
                                      }}
                                      maxFiles={1}
                                      maxSize={MB_20}
                                      maxFilesize={MB_20}
                                      uploadMultiple={false}
                                      multiple={false}
                                      onDrop={async (acceptedFile) => {
                                        if (!values.order_id) {
                                          setErrorMessage(
                                            "Please enter order no."
                                          );
                                          handleErrorModalShow();
                                          return;
                                        }
                                        if (
                                          acceptedFile &&
                                          acceptedFile.length === 0
                                        ) {
                                          setErrorMessage(
                                            "Uploaded files should be less than 20MB"
                                          );
                                          handleErrorModalShow();
                                          return;
                                        }
                                        if (MB_20 <= acceptedFile.size) {
                                          setErrorMessage(
                                            "Attached file is too large"
                                          );
                                          handleErrorModalShow();
                                          return;
                                        }
                                        const [file] = acceptedFile || [];
                                        const { type } = file || {};
                                        setFieldValue(
                                          `products.${index}.fileBeforeUpload`,
                                          type
                                        );
                                        enableLoadingFileUploading();
                                        let uploadProductMediaObj = {
                                          orderId: values.order_id,
                                          file,
                                          container: "media",
                                        };

                                        const uploadOrderAttachmentResponse = await uploadProductMediaApi(
                                          uploadProductMediaObj
                                        );
                                        disableLoadingFileUploading();
                                        const { url, Error } =
                                          uploadOrderAttachmentResponse || {};
                                        if (Error) {
                                          isUnauthorized({
                                            response: uploadOrderAttachmentResponse,
                                          });
                                          setErrorMessage(Error.message);
                                          handleErrorModalShow();
                                          return;
                                        }
                                        setSuccessMessage(
                                          "Product Media uploaded successfully"
                                        );
                                        handleSuccessModalShow();
                                        setFieldValue(
                                          `products.${index}.media_file`,
                                          url
                                        );
                                      }}
                                      key={index}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps({
                                            className:
                                              "dropzone needsclick dz-clickable text-center p-4 py-5 px-6 mb-4 h-100 d-flex align-items-center flex-column justify-content-center",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          {!values.products[index]
                                            .media_file && (
                                            <>
                                              <i
                                                className="fas fa-upload"
                                                style={{ strokeWidth: "1.5px" }}
                                              ></i>
                                              <p style={{ fontSize: "13px" }}>
                                                Drag/drop file here or click on
                                                icon
                                              </p>
                                            </>
                                          )}

                                          <span
                                            className=""
                                            style={{ position: "absolute" }}
                                          >
                                            {isFileUploadLoading && (
                                              <ContentLoader
                                                className={"text-success"}
                                                size={"md"}
                                              />
                                            )}
                                          </span>

                                          {values.products[index]
                                            .media_file && (
                                            <>
                                              {/* // Below code is for Vide Player */}
                                              {values.products[
                                                index
                                              ].fileBeforeUpload.includes(
                                                "video"
                                              ) && (
                                                <Player
                                                  playsInline
                                                  src={
                                                    values.products[index]
                                                      .media_file
                                                  }
                                                  autoPlay
                                                  preload="auto"
                                                  fluid={false}
                                                  width={"100%"}
                                                  height={170}
                                                />
                                              )}

                                              {/* // Below code is for Vide Player */}
                                              {values.products[
                                                index
                                              ].fileBeforeUpload.includes(
                                                "audio"
                                              ) && (
                                                <ReactAudioPlayer
                                                  src={
                                                    values.products[index]
                                                      .media_file
                                                  }
                                                  autoPlay
                                                  controls
                                                />
                                              )}

                                              <span
                                                className="btn btn-circle btn-sm btn-icon btn-danger cursor-pointer"
                                                style={{
                                                  position: "absolute",
                                                  top: "10px",
                                                  right: "25px",
                                                }}
                                              >
                                                <i
                                                  className="fas fa-times d-flex "
                                                  onClick={() => {
                                                    setFieldValue(
                                                      `products.${index}.media_file`,
                                                      ""
                                                    );
                                                  }}
                                                ></i>
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                  {/* <div className="col-12 mt-2 d-flex">
                                    {values.products[index].media_file && (
                                      <>
                                        <Player
                                          playsInline
                                          src={
                                            values.products[index].media_file
                                          }
                                          autoPlay
                                          preload="auto"
                                          fluid={false}
                                          width={700}
                                          height={360}
                                        />
                                        <i
                                          className="fas fa-times text-danger d-flex cursor-pointer"
                                          onClick={() => {
                                            setFieldValue(
                                              `products.${index}.media_file`,
                                              ""
                                            );
                                          }}
                                        ></i>
                                      </>
                                    )}
                                  </div> */}
                                </div>
                              </Card.Body>
                            </Card>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                </div>
                <ButtonToolbar className="d-flex justify-content-end button-wrap">
                  <Button
                    type="submit"
                    variant="btn btn-success mr-3"
                    disabled={loadingAddNewCharm}
                  >
                    Add Order
                    {loadingAddNewCharm && (
                      <span className="ml-3 mr-3 spinner spinner-white"></span>
                    )}
                  </Button>
                  <Button
                    variant="btn btn-light-dark"
                    onClick={() => {
                      resetForm();
                      onClickOfCancelButton();
                    }}
                    disabled={loadingAddNewCharm}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
export default AddNewCharmModal;
