import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { loginApi } from "../../../../axios/services/services";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import ErrorModal from "../../Modal/ErrorModal";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import localStorage from "redux-persist/es/storage";
import { LoginSchema } from "../../../utils/FormikSchema";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { email, password } = values;
      enableLoading();

      let loginObj = { email, password };
      const loginResponse = await loginApi(loginObj);
      disableLoading();
      setSubmitting(false);

      if (loginResponse.Error) {
        const { message } = loginResponse.Error;
        setErrorMessage(message);
        handleErrorModalShow();

        return;
      }

      setUserInstance(loginResponse);
      localStorage.setItem("auth-token", loginResponse.token);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* {/ begin::Head /} */}
        <div className="text-left mb-10">
          <h3 className="h1 font-size-md-h1 font-weight-bolder">
            <img
              src={toAbsoluteUrl("/media/logos/charm.webp")}
              height="70"
              alt=""
            />
          </h3>
        </div>
        {/* {/ end::Head /} */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
          autocomplete="off"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control-lg form-control h-auto py-4 py-md-5 px-6  ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control-lg form-control h-auto py-4 py-md-5 px-6  ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={loading}
              className={`btn-lg btn btn-brand font-weight-bold px-md-9 py-5 my-3 h5 w-100`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
