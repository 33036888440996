import React from "react";
import { Button, Modal } from "react-bootstrap";

function ErrorModal({
  message,
  isErrorModalOpen,
  hideErrorModal,
  errorIcon,
  errorButtonLabel,
  errorOnClick,
}) {
  return (
    <>
      <Modal
        show={isErrorModalOpen}
        onHide={hideErrorModal}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">{errorIcon}</div>

          <p className="text-center fs-16 text-dark confirmation-content">
            {message}
          </p>
          <Button
            variant="btn-lg btn-danger px-8 mt-5 mb-5"
            onClick={errorOnClick}
          >
            {errorButtonLabel}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ErrorModal;
