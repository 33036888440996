// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../AmazonOrdersUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useAmazonOrdersUIContext } from "../AmazonOrdersUIContext";

export function AmazonOrdersTable({
  isLoading,
  isLoadingForDownloadVideoAudio,
  isLoadingForEmail,
  isLoadingForDownloadQRCode,
  amazonOrdersList,
  amazonOrdersListTotalCount,
  data,
}) {
  const amazonOrdersUIContext = useAmazonOrdersUIContext();
  const amazonOrdersUIProps = useMemo(() => {
    return {
      ids: amazonOrdersUIContext.ids,
      setIds: amazonOrdersUIContext.setIds,
      queryParams: amazonOrdersUIContext.queryParams,
      setQueryParams: amazonOrdersUIContext.setQueryParams,
      onClickOfAmazonOrdesViewActionButton:
        amazonOrdersUIContext.onClickOfAmazonOrdesViewActionButton,
      onClickOfDownloadVideoAudioActionButton:
        amazonOrdersUIContext.onClickOfDownloadVideoAudioActionButton,
      onClickOfDownloadQRCodeActionButton:
        amazonOrdersUIContext.onClickOfDownloadQRCodeActionButton,
      onClickOfAmazonOrdesEditActionButton:
        amazonOrdersUIContext.onClickOfAmazonOrdesEditActionButton,
      onClickOfEmailActionButton:
        amazonOrdersUIContext.onClickOfEmailActionButton,
    };
  }, [amazonOrdersUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "75px",
      },
    },
    {
      dataField: "order_no",
      text: "ORDER NO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.OrderNoColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "sku_no",
      text: "SKU NO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.SKUNoColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "video_audio",
      text: "VIDEO/AUDIO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.VideoAudioColumnFormatter,
      formatExtraData: {
        onClickOfDownloadVideoAudioActionButton:
          amazonOrdersUIProps.onClickOfDownloadVideoAudioActionButton,
        isLoadingForDownloadVideoAudio: isLoadingForDownloadVideoAudio,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "qr_code",
      text: "QR CODE",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.QRCodeColumnFormatter,
      formatExtraData: {
        onClickOfDownloadQRCodeActionButton:
          amazonOrdersUIProps.onClickOfDownloadQRCodeActionButton,
        isLoadingForDownloadQRCode: isLoadingForDownloadQRCode,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "text",
      text: "TEXT",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.TextColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "buyer_name",
      text: "BUYER'S NAME",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.BuyersNameColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "email",
      text: "BUYER'S EMAIL",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.BuyersEmailColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "created_at",
      text: "DATE",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateTimeColumnFormatter,
      style: {
        minWidth: "15px",
      },
      attrs: {
        "data-label": "DATE",
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        onClickOfAmazonOrdesViewActionButton:
          amazonOrdersUIProps.onClickOfAmazonOrdesViewActionButton,
        onClickOfAmazonOrdesEditActionButton:
          amazonOrdersUIProps.onClickOfAmazonOrdesEditActionButton,
        onClickOfEmailActionButton:
          amazonOrdersUIProps.onClickOfEmailActionButton,
        isLoadingForEmail: isLoadingForEmail,
        data,
      },
      classes: "pr-md-0 text-right notresponsive",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: amazonOrdersListTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: amazonOrdersUIProps.queryParams.pageSize,
    page: amazonOrdersUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={amazonOrdersList ? amazonOrdersList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  amazonOrdersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                list={amazonOrdersList}
                title={"No Orders Found"}
                description={
                  "There is no orders found, please search with other criteria."
                }
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
