import React from "react";

const renderMessage = ({ id }) => {
  try {
    if (id) {
      return (
        <>
          <i className="fas fa-times text-danger mr-1 text-center"></i>
          {id}
        </>
      );
    }
  } catch {
    return "";
  }
  return "";
};

const ErrorMessage = ({ id }) => {
  return renderMessage({ id });
};

export default ErrorMessage;
