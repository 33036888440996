export const thumbStyle = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

export const thumbInnerStyle = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
  height: "95px",
  width: "95px",
};

export const imgStyle = {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  top: 0,
  left: 0,
};

export const thumbsContainerStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
