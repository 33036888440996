import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required Field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .required("Required Field"),
});

export const UploadOrderAttachmentSchema = Yup.object().shape({
  media: Yup.string().required("Required Field"),
});

export const AddNewCharmSchema = Yup.object().shape({
  order_id: Yup.string()
    .required("Required Field")
    .max(64, "Order Id should be less than 64 characters"),
  buyer_name: Yup.string()
    .required("Required Field")
    .max(64, "Buyer's Name should be less than 64 characters"),
  buyer_email: Yup.string()
    .required("Required Field")
    .email("Email address is invalid")
    .max(64, "Buyer's email be less than 64 characters"),
  recipient_name: Yup.string()
    .required("Required Field")
    .max(64, "Recipient's Name should be less than 64 characters"),
  recipient_email: Yup.string()
    .required("Required Field")
    .email("Email address is invalid")
    .max(64, "Recipient's email be less than 64 characters"),
  address1: Yup.string()
    .required("Required Field")
    .max(64, "Address 1 should be less than 64 characters"),
  address2: Yup.string().max(64, "Address 2 should be less than 64 characters"),
  city: Yup.string()
    .required("Required Field")
    .max(64, "City should be less than 64 characters"),
  state: Yup.string()
    .required("Required Field")
    .max(64, "State should be less than 64 characters"),
  zipcode: Yup.string()
    .required("Required Field")
    .max(64, "Zipcode should be less than 64 digits"),
  products: Yup.array().of(
    Yup.object().shape({
      product_sku: Yup.string().required("Required Field"),
      added_comment: Yup.string().max(
        512,
        "Comment should be less than 512 characters"
      ),
    })
  ),
  // .min(1, "Required Field"),
});
