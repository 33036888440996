import React from "react";
export const OrderNoColumnFormatter = (cellContent, row, rowIndex) => {
  const { order_id } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block"></span>
      <> {order_id || "-"} </>
    </>
  );
};
