/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    onClickOfViewCommentActionButton,
    onClickOfLinkActionButton,
    onClickOfEmailActionButton,
    syncOrderIds,
    isLoadingForGenerateLink,
    isLoadingForEmailOrder,
    data,
  }
) => {
  const { id } = row || {};
  const { id: orderId } = data || {};

  const renderViewCommentButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-primary btn-sm mx-1"
        onClick={() => {
          onClickOfViewCommentActionButton(row);
        }}
      >
        <i className="fas fa-eye"></i>
      </Button>
    );
  };

  // const renderDownloadButton = () => {
  //   const isSync = syncOrderIds.find((oid) => oid.orderId === id);
  //   const { is_sync } = isSync || {};
  //   if (is_sync === 0) {
  //     return (
  //       <Button
  //         variant="btn btn-icon btn-light-primary btn-sm mx-1"
  //         onClick={() => {
  //           onClickOfDownloadActionButton(row);
  //         }}
  //       >
  //         {orderId === id && isLoadingForDownloadAttachment ? (
  //           <ButtonLoader />
  //         ) : (
  //           <i className="fas fa-download"></i>
  //         )}
  //       </Button>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  // const renderUploadButton = () => {
  //   const isSync = syncOrderIds.find((oid) => oid.orderId === id);
  //   const { is_sync } = isSync || {};
  //   if (is_sync === 0) {
  //     return (
  //       <Button
  //         variant="btn btn-icon btn-light-info btn-sm mx-1 font-size-sm"
  //         onClick={() => {
  //           onClickOfUploadActionButton(row);
  //         }}
  //       >
  //         <i className="fas fa-upload"></i>
  //       </Button>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const renderLinkButton = () => {
    const isSync = syncOrderIds.find((oid) => oid.orderId === id);
    const { is_sync } = isSync || {};
    if (is_sync === 1) {
      return (
        <Button
          variant="btn btn-icon btn-light-success btn-sm mx-1 font-size-sm"
          onClick={() => {
            onClickOfLinkActionButton(row);
          }}
        >
          {orderId === id && isLoadingForGenerateLink ? (
            <ButtonLoader />
          ) : (
            <i className="fas fa-link"></i>
          )}
        </Button>
      );
    } else {
      return <></>;
    }
  };

  const renderMailButton = () => {
    const isSync = syncOrderIds.find((oid) => oid.orderId === id);
    const { is_sync } = isSync || {};
    if (is_sync === 1) {
      return (
        <Button
          variant="btn btn-icon btn-light-warning btn-sm mx-1"
          onClick={() => {
            onClickOfEmailActionButton(row);
          }}
        >
          {orderId === id && isLoadingForEmailOrder ? (
            <ButtonLoader />
          ) : (
            <i className="fas fa-envelope"></i>
          )}
        </Button>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-end">
        {renderViewCommentButton()}
        {/* {renderDownloadButton()}
        {renderUploadButton()} */}
        {renderLinkButton()}
        {renderMailButton()}
      </ButtonToolbar>
      <> </>
    </>
  );
};
