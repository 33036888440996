import {
  deleteCall,
  getCall,
  getMediaCall,
  getPDFCall,
  patchCall,
  postCall,
  postCallWithMedia,
} from "../api";
import {
  accessLogListUrl,
  addNewCharmUrl,
  addProductUrl,
  amazonOrdersUrl,
  deleteProductUrl,
  editCharmUrl,
  editProductUrl,
  emailCharmUrl,
  emailOrderUrl,
  exportAccessLogUrl,
  exportProductsUrl,
  generateOrderLinkUrl,
  importProductsUrl,
  loginUrl,
  logoutUrl,
  orderCommentsUrl,
  productListUrl,
  recentAccessLogPDFUrl,
  recentHistoryPDFUrl,
  recentHistoryUrl,
  syncOrderIdUrl,
  uploadDocumentUrl,
  uploadOrderAttachmentUrl,
  uploadProductMediaUrl,
  uploadProfilePicUrl,
} from "./apiList";
import {
  addNewCharmObj,
  addProductObj,
  editCharmObj,
  editProductObj,
  emailCharmObj,
  emailOrderObj,
  generateOrderLinkObj,
  importProductsObj,
  loginObj,
  orderCommentsObj,
  uploadDocumentObj,
  uploadProfilePicObj,
} from "./apiObjects";

export function loginApi({ email, password }) {
  return postCall(loginUrl(), loginObj({ email, password }));
}

export function uploadProfilePicApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadProfilePicUrl(),
    uploadProfilePicObj({ file }),
    containerkey
  );
}

export function recentHistoryApi() {
  return getCall(recentHistoryUrl());
}

export function productListApi({ queryParams }) {
  return getCall(productListUrl({ queryParams }));
}

export function deleteProductApi({ id }) {
  return deleteCall(deleteProductUrl({ id }));
}

export function accessLogListApi({ queryParams }) {
  return getCall(accessLogListUrl({ queryParams }));
}

export function addProductApi({ materialCode, serialNumber }) {
  return postCall(
    addProductUrl(),
    addProductObj({
      materialCode,
      serialNumber,
    })
  );
}

export function editProductApi({
  id,
  materialCode,
  serialNumber,
  is_validate,
}) {
  return patchCall(
    editProductUrl(),
    editProductObj({
      id,
      materialCode,
      serialNumber,
      is_validate,
    })
  );
}

export function recentHistoryPDFApi({ id, customPdfName }) {
  return getPDFCall(recentHistoryPDFUrl({ id }), customPdfName);
}

export function recentAccessLogPDFApi({ id, customPdfName }) {
  return getPDFCall(recentAccessLogPDFUrl({ id }), customPdfName);
}

export function recentHistoryPrintApi({ id }) {
  return getCall(recentHistoryPDFUrl({ id }));
}

export function logoutApi({ userId }) {
  return deleteCall(logoutUrl({ userId }));
}

export function exportProductsApi({ queryParams, filename, extention }) {
  return getPDFCall(exportProductsUrl({ queryParams }), filename, extention);
}

export function exportAccessLogApi({ queryParams, filename, extention }) {
  return getPDFCall(exportAccessLogUrl({ queryParams }), filename, extention);
}

export function uploadDocumentApi(files) {
  return postCallWithMedia(uploadDocumentUrl(), uploadDocumentObj(files));
}

export function importProductsApi({ file }) {
  return postCall(importProductsUrl(), importProductsObj({ file }));
}

export function syncOrderIdApi({ orderId }) {
  return getCall(syncOrderIdUrl({ orderId }));
}

export function uploadOrderAttachmentApi({ orderId, container, file }) {
  return postCallWithMedia(uploadOrderAttachmentUrl({ orderId }), {
    container,
    file,
  });
}

export function generateOrderLinkApi({ orderId, id, media }) {
  return postCall(
    generateOrderLinkUrl({ orderId }),
    generateOrderLinkObj({ id, media })
  );
}

export function orderCommentsApi({ order_id, comment }) {
  return postCall(orderCommentsUrl(), orderCommentsObj({ order_id, comment }));
}

export function emailOrderApi({ order_id, to, url }) {
  return postCall(emailOrderUrl(), emailOrderObj({ order_id, to, url }));
}

export function downloadVideoAudoApi({ mediaUrl, file_name }) {
  return getMediaCall(mediaUrl, file_name);
}

export function addNewCharmApi({
  order_id,
  buyer_email,
  buyer_name,
  recipient_name,
  recipient_email,
  shipping_detail,
  created_by,
  products,
}) {
  return postCall(
    addNewCharmUrl(),
    addNewCharmObj({
      order_id,
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      shipping_detail,
      created_by,
      products,
    })
  );
}

export function uploadProductMediaApi({ orderId, container, file }) {
  return postCallWithMedia(uploadProductMediaUrl({ orderId }), {
    container,
    file,
  });
}

export function amazonOrdersApi({ queryParams }) {
  return getCall(amazonOrdersUrl({ queryParams }));
}

export function emailCharmApi({ charm_detail_id }) {
  return postCall(emailCharmUrl(), emailCharmObj({ charm_detail_id }));
}

export function editCharmApi({
  charm_detail_id,
  order_id,
  buyer_email,
  buyer_name,
  recipient_name,
  recipient_email,
  shipping_detail,
  product_sku,
  media_file,
  added_comment,
}) {
  return patchCall(
    editCharmUrl(),
    editCharmObj({
      charm_detail_id,
      order_id,
      buyer_email,
      buyer_name,
      recipient_name,
      recipient_email,
      shipping_detail,
      product_sku,
      media_file,
      added_comment,
    })
  );
}
