import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { useAmazonOrdersUIContext } from "../AmazonOrdersUIContext";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { dd_MM_yyyy, YYYY_MM_DD_T_HH_MM_SS } from "../../../utils/const";
import moment from "moment";

const initialFilterValues = {
  id: "",
  orderId: "",
  email: "",
  datefrom: "",
  dateto: "",
};

export function AmazonOrdersFilter({}) {
  const [datefrom, setDateFrom] = useState("");
  const [dateto, setDateTo] = useState("");
  const amazonOrdersUIContext = useAmazonOrdersUIContext();
  const amazonOrdersUIProps = useMemo(() => {
    return {
      queryParams: amazonOrdersUIContext.queryParams,
      setQueryParams: amazonOrdersUIContext.setQueryParams,
    };
  }, [amazonOrdersUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { id, orderId, email, datefrom, dateto } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.id = id !== "" ? id : undefined;
    filter.orderId = orderId !== "" ? orderId : undefined;
    filter.email = email !== "" ? email : undefined;

    if (datefrom && dateto) {
      const date = {
        datefrom: datefrom
          ? moment(datefrom).format(YYYY_MM_DD_T_HH_MM_SS)
          : undefined,
        dateto: dateto
          ? moment(dateto).format(YYYY_MM_DD_T_HH_MM_SS)
          : undefined,
      };
      filter.date = date !== "" ? date : undefined;
    }

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      amazonOrdersUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, amazonOrdersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      amazonOrdersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              setDateFrom(null);
              setDateTo(null);
              handleReset();
            }}
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="id"
                  placeholder="Search ID"
                  onBlur={handleBlur}
                  value={values.id}
                  onChange={(e) => {
                    setFieldValue("id", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> ID</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="orderId"
                  placeholder="Search Order No"
                  onBlur={handleBlur}
                  value={values.orderId}
                  onChange={(e) => {
                    setFieldValue("orderId", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> ORDER NO</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="email"
                  placeholder="Search Buyer's Email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> BUYER'S EMAIL</b>
                </small>
              </div>

              <div className="col-lg-2">
                <DatePicker
                  dateFormat={dd_MM_yyyy}
                  className="mb-1 form-control"
                  selected={datefrom}
                  onChange={(date) => {
                    setDateFrom(date);
                    setFieldValue("datefrom", date);
                    handleSubmit();
                  }}
                  placeholderText={"From"}
                  selectsStart
                  startDate={datefrom}
                  endDate={dateto}
                />
                <small className="form-text text-muted">
                  Filter by<b> DATE</b>
                </small>
              </div>

              <div className="col-lg-2">
                <DatePicker
                  className="form-control"
                  dateFormat={dd_MM_yyyy}
                  selected={dateto}
                  onChange={(date) => {
                    setDateTo(date);
                    setFieldValue("dateto", date);
                    handleSubmit();
                  }}
                  placeholderText={"To"}
                  selectsEnd
                  startDate={datefrom}
                  endDate={dateto}
                  minDate={datefrom}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
