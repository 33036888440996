import React from "react";
export const SKUNoColumnFormatter = (cellContent, row, rowIndex) => {
  const { product_sku } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block">{product_sku || "-"}</span>
      <> </>
    </>
  );
};
