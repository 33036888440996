import React from "react";
export const NameColumnFormatter = (cellContent, row, rowIndex) => {
  const { billing } = row || {};
  const { first_name, last_name } = billing || {};
  let name = "-";
  if (first_name) {
    name = first_name;
  }
  if (last_name) {
    name = name.concat(` ${last_name}`);
  }
  return (
    <>
      <span className="text-dark-75 d-block"></span>
      <> {name} </>
    </>
  );
};
