import React from "react";
import { Card } from "react-bootstrap";
import { HistoryFilter } from "./history-filter/HistoryFilter";
import { HistoryTable } from "./history-table/HistoryTable";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";

export function HistoryCard({
  isLoading,
  isLoadingForDownloadVideoAttachment,
  isLoadingForDownloadAudioAttachment,
  isLoadingForGenerateLink,
  isLoadingForEmailOrder,
  historyList,
  historyListTotalCount,
  syncOrderIds,
  data,
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Orders</span>
          </h3>
          {/* <div>
            <Button
              variant="btn btn-light-primary btn-md m-1"
              onClick={handleAddNewProduct}
            >
              <i className="fas fa-sync"></i> Sync
            </Button>
          </div> */}
        </Card.Header>
        <Card.Body>
          <div>
            <HistoryFilter />
            <HistoryTable
              isLoading={isLoading}
              isLoadingForDownloadVideoAttachment={isLoadingForDownloadVideoAttachment}
              isLoadingForDownloadAudioAttachment={isLoadingForDownloadAudioAttachment}
              isLoadingForGenerateLink={isLoadingForGenerateLink}
              isLoadingForEmailOrder={isLoadingForEmailOrder}
              historyList={historyList}
              historyListTotalCount={historyListTotalCount}
              syncOrderIds={syncOrderIds}
              data={data}
            />
            {historyList && historyList.length === 0 && (
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/product-not-found.svg"
                title="No Orders Available, Yet"
                description="There is no Orders available."
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
