import React from "react";
export const BuyersNameColumnFormatter = (cellContent, row, rowIndex) => {
  const { buyer_name } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block"></span>
      <> {buyer_name || "-"} </>
    </>
  );
};
