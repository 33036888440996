import React from "react";
import { Spinner } from "react-bootstrap";
export const ButtonLoader = ({ className, size }) => (
  <Spinner
    className={`${className}`}
    as="span"
    animation="border"
    size={size || "sm"}
    role="status"
    aria-hidden="true"
  />
);
