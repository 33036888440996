import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { AmazonOrdersPage } from "./modules/AmazonOrders/AmazonOrdersPage";
import { HistoryPage } from "./modules/History/HistoryPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/auth/login" to="/dashboard" />
        <Route path="/dashboard" component={HistoryPage} />
        <Route path="/amazon-orders" component={AmazonOrdersPage} />
      </Switch>
    </Suspense>
  );
}
