import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Link } from "react-router-dom";

export function Footer() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div className={`${layoutProps.footerContainerClasses}`}>
        <div className="text-dark order-2 order-md-1 text-center">
          <div className="footer">
            <div className="right"></div>
            <div className="left">
              <p className="m-0">&copy; KAALO LLC. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
