import React, { useEffect, useState } from "react";
import {
  downloadVideoAudoApi,
  emailOrderApi,
  generateOrderLinkApi,
  orderCommentsApi,
  syncOrderIdApi,
} from "../../../axios/services/services";
import { HistoryCard } from "./HistoryCard";
import { HistoryUIProvider } from "./HistoryUIContext";
import SVG from "react-inlinesvg";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { initialFilter } from "./HistoryUIHelpers";
import UploadMediaModal from "../Modal/UploadMediaModal";
import { CONSUMER_KEY, CONSUMER_SECRET } from "../../utils/const";
import ViewCommentsModal from "./view-comments-modal/ViewCommentsModal";
import { isUnauthorized } from "../../utils/utils";

export function HistoryPage({ history }) {
  const [
    isLoadingForDownloadVideoAttachment,
    setLoadingForDownloadVideoAttachment,
  ] = useState(false);
  const [
    isLoadingForDownloadAudioAttachment,
    setLoadingForDownloadAudioAttachment,
  ] = useState(false);
  const [isLoadingForGenerateLink, setLoadingForGenerateLink] = useState(false);
  const [isLoadingForEmailOrder, setLoadingForEmailOrder] = useState(false);
  const [isUploadMediaModal, setUploadMediaModal] = useState(false);
  const [isViewCommentsModal, setViewCommentsModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [historyList, setHistoryList] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [historyListTotalCount, setHistoryListTotalCount] = useState(0);
  const [updateOrderList, setUpdateOrderList] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [woocommerceApi, setWoocommerceApi] = useState(null);
  const [syncOrderIds, setSyncOrderIds] = useState([]);
  const [uploadFileType, setUploadFileType] = useState(null);

  useEffect(() => {
    const api = new WooCommerceRestApi({
      url: "https://charmbykaalo.com",
      // consumerKey: "ck_dbf5bc6d7ca65429a739809faf6f49c0138fe53c",
      // consumerSecret: "cs_34ecf4ba46f9c3c687aeb982b17e21bb56a5f465",
      consumerKey: CONSUMER_KEY,
      consumerSecret: CONSUMER_SECRET,
      version: "wc/v3",
    });
    setWoocommerceApi(api);
  }, []);

  async function getOrderList() {
    enableLoading();
    const { pageNumber, pageSize, filter, sortField, sortOrder } =
      queryParams || {};
    const { orderId, date } = filter || {};
    const { datefrom, dateto } = date || {};
    const ordersResponse = await woocommerceApi.get("orders", {
      page: pageNumber,
      per_page: pageSize,
      search: orderId,
      after: datefrom,
      before: dateto,
      orderby: sortField,
      order: sortOrder,
      action: "edit",
    });
    disableLoading();
    const { status, data, headers } = ordersResponse || {};
    if (status && status === 200) {
      setHistoryList(data);
    }
    if (data) {
      const array = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const { id } = element || {};
        const syncOrderIdResponse = await syncOrderIdApi({ orderId: id });
        const { is_sync } = syncOrderIdResponse || {};
        array.push({ orderId: id, is_sync });
      }
      setSyncOrderIds(array);
    }
    if (headers) {
      const counts = headers["x-wp-total"];
      setHistoryListTotalCount(parseInt(counts) || 0);
    }
  }

  useEffect(() => {
    if (woocommerceApi) {
      getOrderList();
    }
  }, [updateOrderList, woocommerceApi, queryParams]);

  const handleUploadMediaModalShow = () => {
    setUploadMediaModal(true);
  };

  const handleUploadMediaModalHide = () => {
    setUploadMediaModal(false);
  };

  const handleViewCommentsModalShow = () => {
    setViewCommentsModal(true);
  };

  const handleViewCommentsModalHide = () => {
    setViewCommentsModal(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingDownloadVideoAttachment = () => {
    setLoadingForDownloadVideoAttachment(true);
  };

  const disableLoadingDownloadVideoAttachment = () => {
    setLoadingForDownloadVideoAttachment(false);
  };

  const enableLoadingDownloadAudioAttachment = () => {
    setLoadingForDownloadAudioAttachment(true);
  };

  const disableLoadingDownloadAudioAttachment = () => {
    setLoadingForDownloadAudioAttachment(false);
  };

  const enableLoadingGenerateLink = () => {
    setLoadingForGenerateLink(true);
  };

  const disableLoadingGenerateLink = () => {
    setLoadingForGenerateLink(false);
  };

  const enableLoadingEmailOrder = () => {
    setLoadingForEmailOrder(true);
  };

  const disableLoadingEmailOrder = () => {
    setLoadingForEmailOrder(false);
  };

  const historyUIEvents = {
    onClickOfViewCommentActionButton: (row) => {
      setData(row);
      handleViewCommentsModalShow();
    },
    onClickOfDownloadVideoActionButton: (row) => {
      setData(row);
      const { meta_data } = row || {};
      let medias = [];
      for (let index = 0; index < meta_data.length; index++) {
        const element = meta_data[index];
        const { key, value } = element || {};
        if (key === "_wcuf_uploaded_files") {
          const values = Object.values(value);
          for (let i = 0; i < values.length; i++) {
            const mediaFile = values[i];
            const { ID3_info, url } = mediaFile;
            const [mediaInfo] = ID3_info || {};
            const { file_name, type } = mediaInfo || {};
            if (type === "video") {
              const [mediaUrl] = url || "";
              medias.push({ mediaUrl, file_name });
            }
          }
        }
      }
      medias.map((media) => {
        const { mediaUrl, file_name } = media || "";
        downloadVideoOrderAttachment(mediaUrl, file_name);
        return media;
      });
    },
    onClickOfDownloadAudioActionButton: (row) => {
      setData(row);
      const { meta_data } = row || {};
      let medias = [];
      for (let index = 0; index < meta_data.length; index++) {
        const element = meta_data[index];
        const { key, value } = element || {};
        if (key === "_wcuf_uploaded_files") {
          const values = Object.values(value);
          for (let i = 0; i < values.length; i++) {
            const mediaFile = values[i];
            const { ID3_info, url } = mediaFile;
            const [mediaInfo] = ID3_info || {};
            const { file_name, type } = mediaInfo || {};
            if (type === "audio") {
              const [mediaUrl] = url || "";
              medias.push({ mediaUrl, file_name });
            }
          }
        }
      }
      medias.map((media) => {
        const { mediaUrl, file_name } = media || "";
        downloadAudioOrderAttachment(mediaUrl, file_name);
        return media;
      });
    },
    onClickOfUploadVideoActionButton: (row) => {
      setData(row);
      setUploadFileType("Video");
      handleUploadMediaModalShow();
    },
    onClickOfUploadAudioActionButton: (row) => {
      setData(row);
      setUploadFileType("Audio");
      handleUploadMediaModalShow();
    },
    onClickOfLinkActionButton: (row) => {
      setData(row);
      generateOrderLink(row);
    },
    onClickOfEmailActionButton: (row) => {
      setData(row);
      emailOrder(row);
    },
  };

  const handleSuccessOfUploadMedia = () => {
    handleUploadMediaModalHide();
    setUpdateOrderList(Math.random());
  };

  const onClickOfCancelButton = () => {
    handleViewCommentsModalHide();
  };

  const downloadVideoOrderAttachment = async (mediaUrl, file_name) => {
    try {
      enableLoadingDownloadVideoAttachment();
      if (mediaUrl) {
        const downloadResponse = await downloadVideoAudoApi({
          mediaUrl,
          file_name,
        });
      }
      disableLoadingDownloadVideoAttachment();
    } catch (error) {
      console.log("error", error);
      disableLoadingDownloadVideoAttachment();
    }
  };

  const downloadAudioOrderAttachment = async (mediaUrl, file_name) => {
    try {
      enableLoadingDownloadAudioAttachment();
      if (mediaUrl) {
        const downloadResponse = await downloadVideoAudoApi({
          mediaUrl,
          file_name,
        });
      }
      disableLoadingDownloadAudioAttachment();
    } catch (error) {
      console.log("error", error);
      disableLoadingDownloadAudioAttachment();
    }
  };

  const generateOrderLink = async (data) => {
    enableLoadingGenerateLink();
    const { id, meta_data, line_items } = data || {};
    let orderCommentUrl = "";
    let commentStrings = [];
    for (let index = 0; index < line_items.length; index++) {
      const element = line_items[index];
      const { meta_data, product_id } = element || {};
      for (let j = 0; j < meta_data.length; j++) {
        const meta = meta_data[j];
        const { key, value } = meta || {};
        if (key === "comment") {
          commentStrings.push({ product_id, value });
        }
      }
    }
    if (commentStrings && commentStrings.length > 0) {
      let comments = [];
      const cmts = commentStrings.map((comment) => {
        const { product_id, value } = comment || {};
        comments.push({ product_id, comment: value });
        return comment;
      });
      const orderCommentsResponse = await orderCommentsApi({
        order_id: id,
        comment: comments,
      });
      console.log("orderCommentsResponse", orderCommentsResponse);
      const { Error: ErrorOrderComment, message: OrderCommentMessage } =
        orderCommentsResponse || {};
      if (ErrorOrderComment) {
        disableLoadingGenerateLink();
        isUnauthorized({ response: orderCommentsResponse });
        setErrorMessage(ErrorOrderComment.message);
        handleErrorModalShow();
        return;
      }
      orderCommentUrl = OrderCommentMessage;
    }

    const medias = [];
    for (let index = 0; index < meta_data.length; index++) {
      const element = meta_data[index];
      const { key, value } = element || {};
      if (key === "_wcuf_uploaded_files") {
        const values = Object.values(value);
        for (let i = 0; i < values.length; i++) {
          const mediaFile = values[i];
          const { ID3_info, url } = mediaFile;
          const [mediaInfo] = ID3_info || {};
          const { type } = mediaInfo || {};
          if (type === "audio" || type === "video") {
            const [mediaURL] = url || "";
            medias.push({ mediaURL });
          }
        }
      }
    }
    if (orderCommentUrl) {
      medias.push({ mediaURL: orderCommentUrl });
    }
    const generateOrderLinkResponse = await generateOrderLinkApi({
      orderId: id,
      id,
      media: medias,
    });
    disableLoadingGenerateLink();
    const { Error, message } = generateOrderLinkResponse || {};
    if (Error) {
      isUnauthorized({ response: generateOrderLinkResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("Link Generated Successfully.");
    setGeneratedLink(message);
    handleSuccessModalShow();
  };

  const emailOrder = async (data) => {
    const { billing, id } = data || {};
    const { email } = billing || {};
    enableLoadingEmailOrder();
    const emailOrderResponse = await emailOrderApi({
      order_id: id,
      to: email,
      url: generatedLink,
    });
    disableLoadingEmailOrder();
    const { Error } = emailOrderResponse || {};
    if (Error) {
      // isUnauthorized({ response: exportProductsResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("Email Sent Successfully.");
    handleSuccessModalShow();
  };

  return (
    <>
      <HistoryUIProvider
        historyUIEvents={historyUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        {isUploadMediaModal && (
          <UploadMediaModal
            isUploadMediaModal={isUploadMediaModal}
            data={data}
            hideUploadMediaModal={handleUploadMediaModalHide}
            handleSuccessOfUploadMedia={handleSuccessOfUploadMedia}
            uploadFileType={uploadFileType}
          />
        )}

        {isViewCommentsModal && (
          <ViewCommentsModal
            isViewCommentsModal={isViewCommentsModal}
            handleViewCommentsModalHide={handleViewCommentsModalHide}
            data={data}
            onClickOfCancelButton={onClickOfCancelButton}
          />
        )}

        <HistoryCard
          isLoading={isLoading}
          isLoadingForDownloadVideoAttachment={
            isLoadingForDownloadVideoAttachment
          }
          isLoadingForDownloadAudioAttachment={
            isLoadingForDownloadAudioAttachment
          }
          isLoadingForGenerateLink={isLoadingForGenerateLink}
          isLoadingForEmailOrder={isLoadingForEmailOrder}
          historyList={historyList}
          historyListTotalCount={historyListTotalCount}
          syncOrderIds={syncOrderIds}
          data={data}
        />
      </HistoryUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
