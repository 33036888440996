// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../HistoryUIHelpers";
import {
  getHandlerTableChange,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useHistoryUIContext } from "../HistoryUIContext";

export function HistoryTable({
  isLoading,
  isLoadingForDownloadVideoAttachment,
  isLoadingForDownloadAudioAttachment,
  isLoadingForGenerateLink,
  isLoadingForEmailOrder,
  historyList,
  historyListTotalCount,
  syncOrderIds,
  data,
}) {
  const historyUIContext = useHistoryUIContext();
  const historyUIProps = useMemo(() => {
    return {
      ids: historyUIContext.ids,
      setIds: historyUIContext.setIds,
      queryParams: historyUIContext.queryParams,
      setQueryParams: historyUIContext.setQueryParams,
      onClickOfViewCommentActionButton:
        historyUIContext.onClickOfViewCommentActionButton,
      onClickOfDownloadVideoActionButton:
        historyUIContext.onClickOfDownloadVideoActionButton,
      onClickOfDownloadAudioActionButton:
        historyUIContext.onClickOfDownloadAudioActionButton,
      onClickOfUploadVideoActionButton:
        historyUIContext.onClickOfUploadVideoActionButton,
      onClickOfUploadAudioActionButton:
        historyUIContext.onClickOfUploadAudioActionButton,
      onClickOfLinkActionButton: historyUIContext.onClickOfLinkActionButton,
      onClickOfEmailActionButton: historyUIContext.onClickOfEmailActionButton,
    };
  }, [historyUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ORDER ID",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "name",
      text: "NAME",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.NameColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "wordpress_video",
      text: "WORDPRESS VIDEO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.WordpressVideoColumnFormatter,
      formatExtraData: {
        onClickOfDownloadVideoActionButton:
          historyUIProps.onClickOfDownloadVideoActionButton,

        syncOrderIds: syncOrderIds,
        isLoadingForDownloadVideoAttachment: isLoadingForDownloadVideoAttachment,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "wordpress_audio",
      text: "WORDPRESS AUDIO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.WordpressAudioColumnFormatter,
      formatExtraData: {
        onClickOfDownloadAudioActionButton:
          historyUIProps.onClickOfDownloadAudioActionButton,

        syncOrderIds: syncOrderIds,
        isLoadingForDownloadAudioAttachment: isLoadingForDownloadAudioAttachment,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "wordpress_text",
      text: "WORDPRESS TEXT",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.WordpressTextColumnFormatter,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "wordpress_comments",
      text: "WORDPRESS COMMENTS",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.WordpressCommentColumnFormatter,
      style: {
        minWidth: "300px",
      },
    },
    {
      dataField: "s3_video",
      text: "S3 VIDEO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.S3VideoColumnFormatter,
      formatExtraData: {
        onClickOfUploadVideoActionButton:
          historyUIProps.onClickOfUploadVideoActionButton,
        syncOrderIds: syncOrderIds,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "s3_audio",
      text: "S3 AUDIO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.S3AudioColumnFormatter,
      formatExtraData: {
        onClickOfUploadAudioActionButton:
          historyUIProps.onClickOfUploadAudioActionButton,
        syncOrderIds: syncOrderIds,
        data,
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "date",
      text: "DATE",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateTimeColumnFormatter,
      style: {
        minWidth: "170px",
      },
      attrs: {
        "data-label": "DATE",
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        onClickOfViewCommentActionButton:
          historyUIProps.onClickOfViewCommentActionButton,
        onClickOfLinkActionButton: historyUIProps.onClickOfLinkActionButton,
        onClickOfEmailActionButton: historyUIProps.onClickOfEmailActionButton,
        syncOrderIds: syncOrderIds,
        isLoadingForGenerateLink: isLoadingForGenerateLink,
        isLoadingForEmailOrder: isLoadingForEmailOrder,
        data,
      },
      classes: "pr-md-0 text-right notresponsive",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: historyListTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: historyUIProps.queryParams.pageSize,
    page: historyUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={historyList ? historyList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  historyUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
