import React, { useCallback, useState } from "react";
import { ButtonToolbar } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import ErrorModal from "./ErrorModal";
import UpdatedSuccessfulModal from "./UpdatedSuccessfulModal";
import { UploadOrderAttachmentSchema } from "../../utils/FormikSchema";
import { uploadOrderAttachmentApi } from "../../../axios/services/services";
import { useDropzone } from "react-dropzone";
import { AUDIO_EXTENTION, MB_20, VIDEO_EXTENTION } from "../../utils/const";
import { ThumbnailWrapper } from "../../utils/ThumbnailWrapper";

const initialValues = {
  media: "",
};

function UploadMediaModal({
  isUploadMediaModal,
  data,
  hideUploadMediaModal,
  handleSuccessOfUploadMedia,
  uploadFileType,
}) {
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: UploadOrderAttachmentSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      const { media } = values;
      const { id: orderId } = data || {};
      enableLoading();
      let uploadMediaObj = {
        orderId,
        media,
        container: "media",
      };

      const uploadOrderAttachmentResponse = await uploadOrderAttachmentApi(
        uploadMediaObj
      );
      disableLoading();
      const { Error } = uploadOrderAttachmentResponse || {};
      if (Error) {
        const { message } = Error || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      resetForm();
      setSuccessMessage("Attachment Uploaded Successfully.");
      handleSuccessModalShow();
      setTimeout(() => {
        handleSuccessOfUploadMedia();
      }, 2500);
    },
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleOnDeleteOfAttachment = () => {
    setMedia(null);
    formik.setFieldValue("media", "");
  };

  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile && acceptedFile.length === 0) {
      setErrorMessage("Uploaded files should be less than 20MB");
      handleErrorModalShow();
      return;
    }
    if (MB_20 <= acceptedFile.size) {
      setErrorMessage("Attached file is too large");
      handleErrorModalShow();
      return;
    }
    formik.setFieldValue("media", acceptedFile);
    setMedia(acceptedFile);
  });

  const { getRootProps, getInputProps } = useDropzone({
    disabled: media !== null,
    accept:
      uploadFileType === "Audio"
        ? {
            "audio/aac": [".aac"],
            "audio/midi": [".mid"],
            "audio/x-midi": [".midi"],
            "audio/mpeg": [".mp3"],
            "audio/ogg": [".oga"],
            "audio/opus": [".opus"],
            "audio/wav": [".wav"],
            "audio/webm": [".weba"],
            "audio/3gpp": [".3gp"],
            "audio/3gpp2": [".3g2"],
          }
        : {
            "video/x-msvideo": [".avi"],
            "video/mp4": [".mp4"],
            "video/mpeg": [".mpeg"],
            "video/ogg": [".ogv"],
            "video/mp2t": [".ts"],
            "video/webm": [".webm"],
            "video/3gpp": [".3gp"],
            "video/3gpp2": [".3g2"],
          },

    maxFiles: 1,
    maxSize: MB_20,
    maxFilesize: MB_20,
    uploadMultiple: false,
    multiple: false,
    onDrop,
  });

  return (
    <>
      <Modal
        show={isUploadMediaModal}
        onHide={() => {
          formik.resetForm();
          hideUploadMediaModal();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            Upload Attachment
            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={hideUploadMediaModal}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group mt-2">
              <div
                {...getRootProps({
                  className: "dropzone needsclick dz-clickable text-center p-4",
                })}
              >
                <input {...getInputProps()} />
                <i
                  className="fas fa-file-excel text-dark"
                  style={{ strokeWidth: "1.5px" }}
                ></i>
                <p style={{ fontSize: "13px" }}>
                  Drag/drop file here or click on icon
                </p>
              </div>
              {media && (
                <ThumbnailWrapper
                  files={media}
                  handleOnDeleteOfAttachment={handleOnDeleteOfAttachment}
                  uploadFileType={uploadFileType}
                />
              )}

              {formik.touched.media && formik.errors.media ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.media}</div>
                </div>
              ) : null}
            </div>

            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                type="submit"
                variant="btn btn-success mr-3"
                disabled={loading}
              >
                Upload
                {loading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={() => {
                  formik.resetForm();
                  hideUploadMediaModal();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
export default UploadMediaModal;
