import React from "react";
import { Button, Modal } from "react-bootstrap";

function ViewCommentsModal({
  isViewCommentsModal,
  handleViewCommentsModalHide,
  data,
  onClickOfCancelButton,
}) {
  const { id, line_items } = data || {};

  let commentStrings = [];
  for (let index = 0; index < line_items.length; index++) {
    const element = line_items[index];
    const { meta_data, name } = element || {};
    for (let j = 0; j < meta_data.length; j++) {
      const meta = meta_data[j];
      const { key, value } = meta || {};
      if (key === "comment") {
        commentStrings.push({ name, value });
      }
    }
  }

  return (
    <>
      <Modal
        show={isViewCommentsModal}
        onHide={handleViewCommentsModalHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            View Comments
          </h3>
          <p className="text-center h2 font-weight-boldest text-dark mb-10 mt-2">
            {id}
          </p>
          {commentStrings.map((comment, key) => {
            const { name, value } = comment || {};
            return (
              <div className="row" key={key}>
                <div className="col-md-12 mb-4">
                  <h5 className="text-secondary fs-14 font-weight-normal text-left">
                    Product Name : <span className="text-primary">{name}</span>
                  </h5>
                  <p className="text-dark fs-14 font-weight-bold text-left">
                    <span className="text-secondary font-weight-normal">
                      Comment :{" "}
                    </span>{" "}
                    {value}
                  </p>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="btn-lg btn-light-primary mt-5 mb-5 px-10"
            onClick={onClickOfCancelButton}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ViewCommentsModal;
